import * as React from "react";
import LayoutPlain from "../../components/LayoutPlain";
import LegalWarningEN from "content/LegalWarningEN";

const LegalWarningPlain = () => {
  return <LayoutPlain>
    <LegalWarningEN />
  </LayoutPlain>
}

export default LegalWarningPlain;